import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import style from "./ModuleUser.module.css";
import axios from "axios";
import { BASE_URL } from "../../common/constant";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ModuleUserHomepage = () => {
  const [selectedLunchType, setSelectedLunchType] = useState(null);
   const [uniqueType, setUniqueTYpe] = useState([]);
  const [user, setUsers] = useState({});
  const [permission, setPermission] = useState([]);
  const [data, setData] = useState({
    date: "",
  });
  const [menu, setMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [selecteduserType, setSelecteduserType] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SelectedOptionsUT, setSelectedOptionsUT] = useState([]);
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("authtoken")) || ""
  );
  const [fullName, setFullName] = useState(
    JSON.parse(localStorage.getItem("catering_userName")) || ""
  );
  const [userTypes,setUserTypes] = useState([
    { sub_category_name_view: "Athlete", sub_category_name: "athlete" },
    { sub_category_name_view: "Ctos", sub_category_name: "ctos" },
    { sub_category_name_view: "support", sub_category_name: "Support" },
    { sub_category_name_view: "officials", sub_category_name: "Officials" },
  ]);
  // const type_of_users = [
  //   "Athletes Count",
  //   "Support Staff’s Count",
  //   " Technical Officials Count",
  //   "Total CDM’s Count",
  // ];
  // const navigate = useNavigate();

 


  const config = {
    headers: {
      Authorization: `Bearer ${token?.token}`, // For bearer tokens
      // Authorization: `API-Key ${token}`, // For API keys
    },
  };
  const [LunchTypes, setLunchTYpes] = useState([
    { type: " Breakfast" },
    { type: "Lunch " },
    { type: " Dinner" },
    { type: " Refreshments " },
  ]);

  const handleSelectChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeUT = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptionsUT(selectedValues);
  };

  useEffect(() => {
    getUsers();
    getMenu();
    fetUserTypes()
  }, []);

  // console.log("menu", menu);

function fetUserTypes(){
  axios(`${BASE_URL}/userLevel/getuserlevel/5`, config)
  .then(res=>{
    console.log('--------------',res.data);
  setUserTypes(res.data)
}).catch(err=> console.log(err) )
}

  function getMenu() {
    
    axios(`${BASE_URL}/catering/catringMenu`, config)
      .then((res) => {
        // console.log("-------------res.data--------",res.data);

        setMenu(res.data);
        const uniqueTypes = {};
        // Filter the data array and add objects to uniqueTypes based on their type
        const filteredData = res.data.filter((item) => {
          if (!uniqueTypes[item.type]) {
            uniqueTypes[item.type] = true;
            
            return true;
          }
          return false;
          console.log(uniqueTypes)
        });
        setLunchTYpes(filteredData);
        setUniqueTYpe(filteredData);

        //console.log(filteredData)
        // --------------------------
        let sdata = filteredData.map((item) => {
          return {
            type: item.type,
            values: [],
          };
        });

        //----------------------------------
      })
      .catch((err) => console.log(err));
    //  fitering for storing data

    // Empty object
  }

  function handleSubmit() {
    // console.log("Selected optionsUT", SelectedOptionsUT);
    // console.log("selected Mneu", selectedMenu);
    console.log("date", data);
    // console.log("selectedoptions ", selectedPermission, selectedOptions);
    if(data?.date){
      console.log(data.date)
    }
    axios
      .post(`${BASE_URL}/moduleUser/postMenu`, {
        headers: {
          Authorization: `Bearer ${token}`, // For bearer tokens
          // Authorization: `API-Key ${token}`, // For API keys
        },
        body: {
          selectedMenu: JSON.stringify(selectedMenu),
          selectedOptions: selectedPermission,
          data: JSON.stringify(data),
          selectedOptionsUT: SelectedOptionsUT,
        },
      })
      .then((res) => {
        console.log(res);
        Swal.fire(
          'Added!',
          res.data,
          'success'
        )
      
      })
      .catch((err) =>  Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err,
      }));
    setTimeout(() => {
      window.location.reload();
    },2000)
 
  }

  function getUsers() {
    axios(`${BASE_URL}/get/moduleUser`, config)
      .then((res) => {
        console.log("----------------------------------- permission ",res.data);
        localStorage.setItem("moduleUserPermission", res.data?.permission);
        setUsers(res.data);
        // console.log(JSON.parse(res.data.permission));
        setPermission(JSON.parse(res.data.permission));
      })
      .catch((err) => console.log(err));
  }

  const handleLunchType = (type) => {
    setSelectedLunchType(type);
    if (!selectedMenu[type]) {
      setSelectedMenu({ ...selectedMenu, [type]: [] });
    }
  };

  function addToSelectedMenu(item) {
    if (selectedMenu[selectedLunchType]?.includes(item.value)) {
      const filterItems = selectedMenu[item.type].filter(
        (food) => food !== item.value
      );
      setSelectedMenu({
        ...selectedMenu,
        [item.type]: [...filterItems],
      });
    } else {
      setSelectedMenu({
        ...selectedMenu,
        [item.type]: [...selectedMenu[item.type], item.value],
      });
    }
  }

  // console.log("selectedMenu", selectedMenu);

  function handleChange(e) {
    console.log(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(selectedMenu, data);
  }

  const handleSelectUserType = (e) => {
    setSelecteduserType([...e]);
    const selectedValues = Array.from(e);
    setSelectedOptionsUT(selectedValues);
  };

  const handlePermissionType = (e) => {
    setSelectedPermission([...e]);
    // const selectedValues = Array.from(e);
    // setSelectedOptions(selectedValues);
  };
 

  return (
    <div className={style.moduleUserContainer}>
      <div className={style.NavbarConatiner}>
        <Navbar name={fullName} isShow={true} />
      </div>
      <div className={style.body}>
        <p className={style.header}>
          {user.type === "fop"
            ? "FOP User"
            : "Accommodation-based Hotels"}
        </p>
        <div className={style.contentform}>
          <div className={style.menus}>
            {LunchTypes.map((item, idx,arr) => {
              console.log("arr-------",arr)
              return(
              <button
                key={idx}
                className={`${style.itemNameButtons}  ${
                  item.type == selectedLunchType && style.active_breakFast
                }  `}
                onClick={() => handleLunchType(item.type)}
              >
                {item.type}
              </button>
            )
          
          
          }
            
            )}
          </div>
          
          {/* display all available menus */}
          <div className={style.lunchMenuWrap}>
            {menu.length > 0 &&
              menu.map((item, index) => {
                // console.log(item)
                return (
                  <>
                    <div className={style.lunchMenu} key={index}>
                      {item.type == selectedLunchType && (
                        <h3 className={style.lunchItemsMenu}>
                          {/* {item.type}: */}
                          {item.value}
                          <div>
                            <button
                              className={style.AddButtonMenu}
                              onClick={(e) => {
                                addToSelectedMenu(item);
                              }}
                            >
                              {selectedMenu[selectedLunchType]?.includes(
                                item.value
                              )
                                ? `-`
                                : `+`}
                            </button>{" "}
                            
                          </div>
                        </h3>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
{/* date day input */}
              <label htmlFor="date">Select Date</label>
          <input type="date" className={style.date_input} onChange={handleChange} name="date" />
          
          {/* <select name="date" id="date" onChange={handleChange}>
            {daysOfWeek.map((item, index) => {
              return (
                <option value={item} key={index + item}>
                  {" "}
                  {item}
                </option>
              );
            })}
          </select> */}

          {/* ======================================================= */}
          <label htmlFor="demo-multiple-checkbox">Select Venue</label>

          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            placeholder="Select the Permission"
            value={selectedPermission}
            onChange={(e) => handlePermissionType(e.target.value)}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            className={style.selectOptions}
            // MenuProps={MenuProps}
          >
            {permission?.map((name) => (
              <MenuItem
                key={
                  name.VenueName != undefined ? name.VenueName : name.HotelName
                }
                value={
                  name.VenueName != undefined ? name.VenueName : name.HotelName
                }
              >
                <Checkbox
                  checked={
                    selectedPermission.indexOf(
                      name.VenueName != undefined
                        ? name.VenueName
                        : name.HotelName
                    ) > -1
                  }
                />
                <ListItemText
                  primary={
                    name.VenueName != undefined
                      ? name.VenueName
                      : name.HotelName
                  }
                />
              </MenuItem>
            ))}
          </Select>

          <label htmlFor="demo-multiple-checkbox">Select UserTypes</label>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            placeholder="Select the UserType"
            value={selecteduserType}
            onChange={(e) => handleSelectUserType(e.target.value)}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            className={style.selectOptions}
            // MenuProps={MenuProps}
          >
            {userTypes.map((name) => (
              <MenuItem key={name.value} value={name.sub_category_id}>
                <Checkbox checked={selecteduserType.indexOf(name.sub_category_id  ) > -1} />
                <ListItemText primary={name.sub_category_name_view} />
              </MenuItem>
            ))}
          </Select>

          <button className={style.UserModuleSubmitBtn} onClick={handleSubmit}>
            {" "}
            Submit{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleUserHomepage;
