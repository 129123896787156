import React from 'react'
import navbarLogo from "../../../assets/goa-logo.png"
import style from "./SubNavbar.module.css"
import { Link, useNavigate} from "react-router-dom";


function SubNavbar() {
  const navigate = useNavigate()

  function handleLogout(){
    localStorage.removeItem("authtoken");
    localStorage.removeItem("catering_userName");
    localStorage.removeItem("catering_user_level");
    navigate('/')
}

  return (
    <div className={style.subnavbar}>
      <div>
       <img src={navbarLogo} className={style.navbarlogo}/>
      </div>
      <div className={style.rightSubNavbar}>
        <div className={style.rightButtons}>
         <Link to="/admin">
         <button className={style.rightTwoButton}>Home</button>
         </Link>
        </div>
       <div className={style.rightButtons}>
       <button onClick={handleLogout} className={style.rightTwoButton}>Logout</button>
       </div>
      </div>
    </div>
  )
}

export default SubNavbar;
