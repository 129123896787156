import React, { useEffect, useState } from 'react'
import style from "./Login.module.css"
import axios from "axios"
import { json, useNavigate } from 'react-router-dom';
import IOA_Logo from '../../assets/IOA_Logo.png';
import { BASE_URL } from "../../common/constant.js"
import Swal from 'sweetalert2';

const Login = () => {
  let [data, setData] = useState({})
  const navigate = useNavigate();
  let [token, setToken] = useState(JSON.parse(localStorage.getItem("authtoken")) || {});
  const [user_level, setUserLevel] = useState(localStorage.getItem("catering_user_level") || "")

  useEffect(() => {
    if (token.token?.length > 0) {
      console.log(token.token, user_level)
      if (user_level == "3") {
        navigate("/admin")
      }
      else if (user_level == "5") {
        navigate("/module_user")
      }
    }
  }, [])



  let handleChange = (e) => {
    setData(data => {
      return { ...data, [e.target.name]: e.target.value }
    })

  };
  function handleSucessfullLogin(res) {
    const { token, user_level, fullname } = res.data
    //  console.log(token,user_level)
    localStorage.setItem("authtoken", JSON.stringify({ "token": token }));
    localStorage.setItem("catering_user_level",res?.data?.user_level);
    localStorage.setItem("catering_userName", JSON.stringify(fullname));

    console.log(res)
    if (res?.data?.user_level == '3') {
      navigate("/admin")
    }
    else if (res?.data?.user_level  >= '4') {
      navigate("/module_user")
    }
    else {
      return Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: "Not Authorized"
      });
    }
  }

  const handeleSubmit = (e) => {

    e.preventDefault();
    if (!data) {
      return Swal.fire({
        icon: 'error',
        title: 'Enter details',
      })
    }


    axios.post(`${BASE_URL}/admin/adminLogin`, data)
      .then(res => {
        console.log(res)
        handleSucessfullLogin(res);
        Swal.fire({
          icon: 'success',
          title: 'Login Successful',
          text: 'You have successfully logged in.'
        });
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: err?.response?.data?.message || "Something Went Wrong"
        });


      });
  }

  return (
    <div className={style.background}>
      <div className={style.empty}>
        <div className={style.conta_left}>
        </div>
        <div className={style.main_page}>
          <div className={style.second_dev} >
            {/* Move the image inside the login box */}
            <img
              className={style.login_header_img}
              src={IOA_Logo}
              alt="Logo"
            />
            <div>
              <input className={style.input}
                onChange={handleChange}
                type="text"
                placeholder="Email"
                name="email"
                required
              />
              <input className={style.input}
                onChange={handleChange}
                type="password"
                placeholder="Password"
                name="password"
                required
              />

              <div className={style.ham_login} onClick={handeleSubmit}>
                <a
                  className={style.nav_link}
                >
                  <span >LOGIN</span>
                  <div className={style.liquid}></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login


