import React, { useEffect, useState } from 'react';
import areAllPropertiesFilled from "../../../common/areAllPropertiesFilled.js";
import style from "../AddFopBasedVenue/AddFopBasedVenues.module.css";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../../common/constant.js"
import { NavItem } from 'react-bootstrap';
import SubNavbar from '../SubNavbar/SubNavbar.jsx';
const AddSubAdmin = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
    confirm_password: "",
    type: "fop",
    

  });
  const [permission, setPermission] = useState([])
  const [venueData, setvenueData] = useState([])
  useEffect(() => {


    function fetchFirstApi() {
      return fetch(`${BASE_URL}/get/fopBasedVenue`)
        .then(response => response.json())
        .catch(error => {
          console.error('Error fetching data from the first API:', error);
          throw error;
        });
    }

    // Function to fetch data from the second API
    function fetchSecondApi() {
      return fetch(`${BASE_URL}/get/accomodationBasedVenue`)
        .then(response => response.json())
        .catch(error => {
          console.error('Error fetching data from the second API:', error);
          throw error;
        });
    }
    Promise.all([fetchFirstApi(), fetchSecondApi()])
      .then(data => {
        const firstApiData = data[0];
        const secondApiData = data[1];
        console.log(firstApiData, secondApiData)
        // Process the data from both APIs
        setvenueData([firstApiData, secondApiData]);
      })
      .catch(error => {
        console.error('Error fetching data from one or both APIs:', error);
      });

  }, [])

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleChange = (e) => {

    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleTYpeChange(e) {
    setPermission([])
    setData({ ...data, [e.target.name]: e.target.value })
  }
  function appendData() {
    console.log("append Dat")
    const formData = new FormData();
    formData.append('file', file);
    formData.append('permission', JSON.stringify(permission))
    // Append each property in the `data` object to the FormData
    console.log("--------------------------------------")
    for (const key in data) {
      console.log(key, data[key])
      formData.append(key, data[key]);
    }

    // Append `permission` as an array to FormData
    // for (let i = 0; i < permission.length; i++) {
    //   formData.append('permission[]', permission[i]);
    // }


    return formData

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("hs")
    if (!areAllPropertiesFilled(data)) {
      return Swal.fire('Please Enter all Details!');
    };
    if (!file) {
      return Swal.fire('Please add image!');
    }
    if (data.password !== data.confirm_password) {
      return Swal.fire('Passwords do not match!');
    }
    // console.log( "file data fasdfasdfasd",  data,permission,file)
    const formData = appendData()
    console.log("form Datafsdfasdfadsfadfdfget", formData.get('email'))                                               
    axios.post(`${BASE_URL}/subadmin/add`, formData)
      .then(res => {
        Swal.fire(
          'Added!',
          res.data?.msg,
          'success'
        )
        window.location.reload(false);

      }).catch((error) => {
        console.log('error',error)
        Swal.fire(
          'Error!',
          error.data?.msg,
          'error'
        )
      })


  };
  return (
    <div className={style.mainContainer}>
      <div className={style.Nav}>
        <SubNavbar />
      </div>
      <div className={style.FOPform}>
        <h2>Add Sub Admin</h2>
        <form onSubmit={handleSubmit} className={style.formFieldsWrap}>
          <div className={style.fopVenueChilds}>
            <label className={style.label}>Upload an Image:</label>
            <input className={style.input} type="file" accept="image/*" onChange={handleFileChange} />
          </div>
          <div className={style.fopVenueChilds}>
            <label className={style.label}>Name:</label>
            <input className={style.input} name='name' type="text" value={data.name} onChange={handleChange} />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label}>Email:</label>
            <input className={style.input} name='email' type="email" value={data.email} onChange={handleChange} />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label}>Phone number:</label>
            <input className={style.input} name='phone_number' type="number" value={data.phone_number} onChange={handleChange} />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label}>Password:</label>
            <input className={style.input} name='password' type="password" value={data.password} onChange={handleChange} />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label}>Confirm Password:</label>
            <input className={style.input} name='confirm_password' type="password" value={data.confirm_password} onChange={handleChange} />
          </div>

            {/* display permission places */}
          <div className={style.fopVenueChilds}>
            <select className={style.select} name="type" id="type" onChange={handleTYpeChange} >
              <option value="fop">Fop</option>
              <option value="accomodation"> Accomodation </option>
            </select>
          </div>
          
          <div className={style.hotelList}>
       
            {
              data.type == 'fop' ? <>
                 <div className={style.addAccomodationPerm}>Add FOP Permissions</div>
                {/* <div > add accomodation permission </div> */}
                {
                  venueData[0] && venueData[0].map((item, index) => {
                    console.log(item)
                    return <div key={index} onClick={() => {
                      // console.log( data.permission, item)

                      for (let i = 0; i < permission.length; i++) {
                        if (permission[i].VenueName == item.VenueName) { return }
                      }
                      setPermission([...permission, {
                        'clusterName': item.ClusterName,
                        'VenueName': item.VenueName,
                        'hvid':item.venue_code
                      }]);
                      console.log('--------permissiom',permission)
                      setData(data)
                    }} > <div className={style.addVenueName}>{item.VenueName} </div></div>
                  })
                }
              </> :
                <>
                  <div className={style.addAccomodationPerm} > Add Accomodation Permission</div>
                  {
                    venueData[1].map((item, index) => {
                      console.log(item)
                      return <div className={style.permDiv} key={index} onClick={() => {
                        for (let i = 0; i < permission.length; i++) {
                          if (permission[i].HotelName == item.HotelName) { return }
                        }
                        setPermission([...permission, {
                          "HotelName": item.HotelName,
                          "hvid":item.id
                          
                        }]);
                        console.log(permission)
                        setData(data)
                      }}>{item.HotelName}</div>
                    })
                  }
                </>

            }
          </div>

          <div className={style.currentPermissions}>
            <label className={style.label}>Current Permission:</label>
            <div className={style.locationsList}>
              {permission.map((item, index) => {
              // console.log("per", item)
              return <div key={index}  className={style.UserModulePermission}>  {item.VenueName} {item.HotelName} < div onClick={() => {
                // console.log(index)
                permission.splice(index, 1)
                setPermission([...permission])
              }} > x  </div> </div>
            })}    </div>

            </div>

          <div>
            <button className={style.button} type="submit" >Submit </button>
          </div>
        </form>
      </div>
    </div>
  )
};

export default AddSubAdmin