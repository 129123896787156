import React, { useEffect, useState } from "react";
import style from "./ManageSubAdmin.module.css";
import SubNavbar from "../SubNavbar/SubNavbar";
import { Table } from "react-bootstrap";
import { BASE_URL } from "../../../common/constant";
import axios from "axios";
import Swal from "sweetalert2";

function ManageSubAdmin() {
  const [users, setUsers] = useState([]);
  let [token, setToken] = useState(
    JSON.parse(localStorage.getItem("authToken"))?.token || {}
  );

  // Edit modal
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState({
    password: "",
    phone_number: "",
  });
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${token}` // For bearer tokens
      // Authorization: `API-Key ${token}`, // For API keys
    },
  };

  const fetchData = async () => {
    try {
      axios
        .get(`${BASE_URL}/get/moduleUser`, config)
        .then((res) => console.log(res))
        .then((jsonData) => setData(jsonData));

      //   console.log("DATA",jsonData)
    } catch (error) {
      console.log("Error fetching data:", error);
      console.error("Error fetching data for fetched Data:", error);
    }
  };

  const handleEdit = (id, index) => {
    // console.log( "-------------------------------------------Clicked", users[index]);
    const selectedItem = users[index];
    setEditedData({
      ...selectedItem,
    });
    setIsEditModalVisible(true);
    console.log(isEditModalVisible, selectedItem, "selecteditem");
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleInputChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    console.log("----------------------", editedData);
    try {
      const response = await fetch(
        `${BASE_URL}/subadmin/update/${editedData.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            password: editedData.password,
            phone_number: editedData.phone_number,
          }),
        }
      );

      if (response.ok) {
        // console.log('Data updated successfully.');
        Swal.fire(
          'Updated!',
          'Sub Admin Details Updated Successfully!',
          'success'
        )
        handleCloseEditModal();
        window.location.reload();
      } else {
        
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to Update Data!',
        })
        console.error("Failed to update data.");
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to Update Data!',
      })
    }
  };

  // ************************************************

  useEffect(() => {
    getUsers();
    // fetchData();
  }, []);
  function getUsers() {
    axios(`${BASE_URL}/subadmin/get`, config)
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  }

  function handleDelete(id, index) {
    axios
      .delete(`${BASE_URL}/subadmin/deleteSubAdmin/${id}`)
      .then((res) => {
        Swal.fire(
          'Deleted!',
          'Deleted Sub Admin Successfully',
          'success'
        );
        getUsers();
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.error,
        });
      });
  }

  return (
    <>
      <div className={style.popUpEditSubAdmin}></div>
      <div className={style.subAdmin}>
        <SubNavbar />
        <div className={style.subAdminContainer}>
          <h2>Manage Sub Admin</h2>
          <h3>Home - Manage KI Modules Admin</h3>
        </div>
        <div className={style.subAdminDetails}>
          <h2 style={{ textAlign: "center" }}>Manage Sub Admins</h2>

          {/* /// Edit Modals */}
          {isEditModalVisible && (
            <>
              <div className={style.modal}>
                <div className={style.modalcontent}>
                  <span className={style.close} onClick={handleCloseEditModal}>
                    &times;
                  </span>
                  <h2>Edit Details</h2>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="name">
                      {" "}
                      <h3>Name:{editedData.name}</h3>{" "}
                    </label>
                  </div>
                  <div >
                    <label htmlFor="email">Email: </label>{" "}
                    <h3>{editedData.email}</h3>
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="email">Password: </label>
                    <input
                      type="text"
                      id="password"
                      name="password"
                      value={editedData.password}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="phone_number">Phone Number: </label>
                    <input
                      type="text"
                      id="phone_number"
                      name="phone_number"
                      value={editedData.phone_number}
                      onChange={handleInputChangeEdit}
                    />
                  </div>

                  <button onClick={handleSave} className={style.updateButton}>
                    Update
                  </button>
                </div>
              </div>
            </>
          )}

          {/* ********************************************************** */}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th> user Name</th>
                <th>user Email</th>
                <th>user phone number</th>
                <th>Type </th>
                <th>Permission</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 &&
                users.map((item, index) => {
                  // console.log(item)
                  const permission = JSON.parse(item.permission)

                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phone_number}</td>
                      <td>{item.type}</td>
                      <td>{
                        permission?.length > 0 && permission.map((itm, ind) => {
                          return <div> {itm.VenueName ? <><strong>Venue: </strong>  {itm.VenueName}   </> : <>
                            <strong> Hotel :  </strong>  {itm.HotelName}
                          </>} </div>

                        })

                      }</td>
                      <td>
                        <div className={style.subAdminActions}>
                          <button
                            className={style.EditsubAdmin}
                            onClick={() => {
                              handleEdit(item.id, index);
                            }}
                          >
                            Edit{" "}
                          </button>
                          <button
                            className={style.EditsubAdmin}
                            onClick={() => {
                              handleDelete(item.id, index);
                            }}
                          >
                            Delete{" "}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default ManageSubAdmin;

// const response = await fetch(`${BASE_URL}/get/moduleUser`,config);
// console.log("--------------------------",response,"-----------------")
// const jsonData = await response.json();
