import React from 'react'
import { useNavigate } from 'react-router-dom';
import style from "./Navbar.module.css"
const Navbar = ({isShow, ...props}) => {
  const navigate = useNavigate()
  //console.log( props.data)
  function handleLogout() {
    localStorage.removeItem("authtoken");
    localStorage.removeItem("catering_userName");
    localStorage.removeItem("catering_user_level");
    navigate('/')
  }
  return (
    <>
      <div className={style.mainDiv}>
        <div className={style.body}>
          <div className={style.welcomeDiv}> Welcome  {props.name} !   </div>
          <div className={style.dayDiv}>
            Have a great day!
          </div>
        </div>

        <div>
      {isShow && <button className={style.buttonNav} onClick={()=>navigate('/module_user/manageMenu')}> Manage Menu </button>}
          
          <button className={style.buttonNav}
            onClick={() => navigate('/')} >
            Home
          </button>

          <button
            className={style.buttonNav}
            onClick={handleLogout} >
            Logout
          </button>

        </div>
      </div>
    </>
  )
}

export default Navbar