import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "../AddFopBasedVenue/AddFopBasedVenues.module.css";
import Swal from "sweetalert2";
import SubNavbar from "../SubNavbar/SubNavbar";
import axios from "axios";
import { BASE_URL } from "../../../common/constant"
import areAllPropertiesFilled from "../../../common/areAllPropertiesFilled";


const AddAccomodationvenue = () => {
    const [hotelList, setHotelList] = useState([])
    const [hide ,setHide]=useState(true)
    const [hotelDetails, setHotelDetails] = useState({
        HotelName: "",
        Hotel_id:"",
        POCName: "",
        POCContactNumber: "",
        HotelState: "",
        HotelCity: "",
        Pincode: "",
        GoogleMapLink: "",

        TotalAthletesCapacityCount: "",
        TotalSupportStaffsCount: "",
        TotalTechnicalOfficialsCount: "",
        TotalCDMsCount: ""

    });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetHotelList()
    }, [])
    function fetHotelList() {
        axios(`${BASE_URL}/hotel/getAccomodation`)
            .then(res => {
                console.log(res);
                setHotelList(res.data)
            })
            .catch(err => console.log(err))
    }

    // function AddAccomodationVenue() {
    //   return (
    //     <div>
    //       Add Accomodation Venue
    //     </div>
    //   )
    // }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setHotelDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
      };

    const handleAutoFetch = () => {
        // Implement auto-fetch logic here
        setShowModal(true);
    };

    const handleSubmit = () => {
        console.log("Form data submitted:", hotelDetails);
        // if(!areAllPropertiesFilled(hotelDetails)) 
        //     return Swal.fire('Please Enter all Details!')

        axios.post(`${BASE_URL}/hotel/accomodation_cartering`, hotelDetails)
            .then(res => {
                if (res.data == "already exist") {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.msg,
                    })
                    window.location.reload();
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: res.msg,
                });
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                })
            });
    };


    return (
        <div className={style.mainContainer}>
            <div className={style.Nav}>
                <SubNavbar />
            </div>
            {showModal && (
            <div className={style.displayHotelBoc} >
            <span onClick={handleCloseModal} className="closeButton">
              &times; 
            </span>
                {
                    hotelList?.length>0 && hotelList.map(item=>{
                        let  address= item.address.split(",");
                            // console.log(item)
                        return <p key={item.id} onClick={(e)=>{
                           
                            setHotelDetails({
                                ...hotelDetails,
                                Hotel_id:item.id,
                                HotelName: item.name,
                                POCName: item.pocName,
                                POCContactNumber: item.pocContact,
                                HotelState: address[address.length-2],
                                HotelCity: address[address.length-3],
                                Pincode: address[address.length-1],
                                GoogleMapLink: item.mapLink,
                        
                            })
                            console.log(item.id)
                            handleCloseModal()
                        }} > {item.name}{address[address.length-3]} </p>
                    })
                }
            </div>
            )}
            <div className={style.FOPform}>
                <h1 className={style.heading}>Add Accommodation-based Hotels</h1>

                


                <div className={style.formFieldsWrap}>

                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Hotel Name</label>
                        <input
                            type="text"
                            name="HotelName"
                            value={hotelDetails.HotelName}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>

                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Catering POC Name
                        <button onClick={handleAutoFetch} className={style.fixedButtons} style={{ position: 'fixed', top: '10%', right: '100px' }}>
                    Auto Fetch
                </button>
                        </label>

                        <input
                            type="text"
                            name="POCName"
                            value={hotelDetails.POCName}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>
                    <div className={style.fopVenueChilds}>

                        <label className={style.label}>Enter Catering POC Contact</label>

                        <input
                            type="tel"
                            name="POCContactNumber"
                            value={hotelDetails.POCContactNumber}
                            pattern="[0-9]{10}" maxLength="10"
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>

                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Hotel State</label>

                        <input
                            type="text"
                            name="HotelState"
                            value={hotelDetails.HotelState}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>



                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Hotel City</label>

                        <input
                            type="text"
                            name="HotelCity"
                            value={hotelDetails.HotelCity}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>

                    <div className={style.fopVenueChilds}>

                        <label className={style.label}>Enter Pin code</label>

                        <input
                            type="text"
                            name="Pincode"
                            value={hotelDetails.Pincode}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>
                    <div className={style.fopVenueChilds}>

                        <label className={style.label}>Enter Google Map link</label>

                        <input
                            type="text"
                            name="GoogleMapLink"
                            value={hotelDetails.GoogleMapLink}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>


                </div>
                <div className={style.formFieldsWrap}>



                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Total Athletes</label>

                        <input
                            type="number"
                            name="TotalAthletesCapacityCount"
                            value={hotelDetails.TotalAthletesCapacityCount}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>
                    
                    <div className={style.fopVenueChilds}>

                        <label className={style.label}>Enter Total Support Staff’s</label>

                        <input
                            type="number"
                            name="TotalSupportStaffsCount"
                            value={hotelDetails.TotalSupportStaffsCount}
                            onChange={handleChange}
                            className={style.input}
                        />

                    </div>
                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Technical Officials</label>

                        <input
                            type="number"
                            name="TotalTechnicalOfficialsCount"
                            value={hotelDetails.TotalTechnicalOfficialsCount}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>

                    <div className={style.fopVenueChilds}>
                        <label className={style.label}>Enter Total CDM’s</label>

                        <input
                            type="number"
                            name="TotalCDMsCount"
                            value={hotelDetails.TotalCDMsCount}
                            onChange={handleChange}
                            className={style.input}
                        />
                    </div>

                </div>
                <button onClick={handleSubmit} className={style.button}>Submit</button>


                {/* <button className={style.fixedButtons} style={{ position: 'fixed', top: '7%', right: '70px', textDecoration: 'none' }}>
                    <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit' }}>Home </Link>
                </button> */}
            </div>


        </div>
    );
};

export default AddAccomodationvenue;
