import React, { useEffect, useState } from "react";
import style from "../ManageSubAdmin/ManageSubAdmin.module.css";
import SubNavbar from "../SubNavbar/SubNavbar";
import { Table } from "react-bootstrap";
import { BASE_URL } from "../../../common/constant";
import axios from "axios";

function ForecastRequestManagement() {
  const [details, setdetails] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [selectedMealLocation, setSelectedMealLocation] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");

  // Meal location Filteration
  useEffect(() => {
    let filteredData = details;
    const filterData = () => {
      if (selectedMealLocation) {
        console.log("selectedMealLocation:", selectedMealLocation);
        // console.log("item.date:", item.date);
        filteredData = details.filter(
          (item) => item.meal_location === selectedMealLocation
        );
      }
      console.log("filteredData meal location", filteredData);
      setFilteredDetails(filteredData);
    };

    filterData();
  }, [selectedMealLocation, details]);

  // Date filteration logic
  useEffect(() => {
    let filteredData = details;
    const filterData = () => {
      if (selectedDate) {
        filteredData = details.filter((item) => {
          console.log("selectedDate:", selectedDate);
          console.log("item.date:", item.date);
          return item.date === selectedDate;
        });
      }

      setFilteredDetails(filteredData);
    };

    filterData();
  }, [selectedDate, details]);

  // hotel filteration logic
  useEffect(() => {
    let filteredData = details;
    // Create a function to filter the data based on selected filters
    const filterData = () => {
      if (selectedHotel) {
        filteredData = details.filter((item) => {
          if (item.hotel_name) {
            return item.hotel_name === selectedHotel;
          } else {
            return item.venue_name === selectedHotel;
          }
        });
      }

      setFilteredDetails(filteredData);
    };

    filterData();
  }, [selectedHotel, details]);

  const fetchData = async () => {
    try {
      axios.get(`${BASE_URL}/get/forecastingDetails`).then((res) => {
        // console.log(res.data);
        // trim date her
        res.data.map((item) => {
          item.date = item.date.split("T")[0];
        });
        console.log(res.data, "RESPONSE DATA");
        const uniqueHotels = Array.from(
          new Set(res.data.map((item) => item.hotel_name || item.venue_name))
        );
        console.log(uniqueHotels, "unique Hotels");
        setdetails(res?.data || []);
        setHotels(uniqueHotels);
      });
    } catch (error) {
      console.log("Error fetching data:", error);
      console.error("Error fetching data for fetched Data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={style.popUpEditSubAdmin}></div>
      <div className={style.subAdmin}>
        <SubNavbar />
        <div className={style.subAdminContainer}>
          <h2>ForeCasting</h2>
          <div className={style.foreCastingDate}>
            <div>
              <h3 className={style.filterDate}>Filter</h3>
            </div>
            <div>
              <input
                className={style.dateInputforecasting}
                type="date"
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  console.log(selectedDate, "selected date");
                }}
              />
            </div>
            <div>
              <select
                value={selectedHotel}
                className={style.hotelFilter}
                onChange={(e) => {
                  setSelectedHotel(e.target.value);
                  console.log(selectedHotel, "selected hotel");
                }}
              >
                <option value="">Select a hotel</option>
                {hotels.map((hotel, index) => {
                  return (
                    hotel && (
                      <option key={index} value={hotel}>
                        {hotel}
                      </option>
                    )
                  );
                })}
              </select>
            </div>

            <div>
              <select
                id="mealLocationFilter"
                value={selectedMealLocation}
                className={style.mealLocationFilter}
                onChange={(e) => {
                  setSelectedMealLocation(e.target.value);
                  console.log(selectedMealLocation, "selected meal location");
                }}
              >
                <option value="">Select Meal Location</option>
                <option value="Accommodation">Accommodation</option>
                <option value="Venue">Venue</option>
              </select>
            </div>
          </div>
        </div>
        <div className={style.subAdminDetails}>
          <h2 style={{ textAlign: "center" }}>Forecasting</h2>

          {/* ********************************************************** */}
          <div className={style.table2}>
            <div
              className={`${style.tableHeader} position-relative rounded-top-left-sm py-4 fw-bold body-text`}
              style={{
                borderTopLeftRadius: "1rem",
              }}
            >
              <div>Hotel Id</div>
              <div>Menu Type</div>
              <div>Date</div>
              <div>Hotel Name</div>
              <div>Meal Location</div>
              <div>Count</div>
            </div>
            <div className={style.bodyContainer}>
              {filteredDetails?.length > 0 &&
                filteredDetails?.map((item, index) => {
                  return (
                    <div
                      style={{
                        background: index % 2 === 0 ? "#D8C7F8" : "#EEE5FF",
                      }}
                      className={style.divContainer}
                      key={index}
                    >
                      <div>{item.hotel_id || item.venue_code}</div>
                      <div>{item.menu_type}</div>
                      <div>{item.date}</div>
                      <div>{item.hotel_name || item.venue_name}</div>
                      <div>{item.meal_location}</div>
                      <div>{item.count}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForecastRequestManagement;
