import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../common/constant'
import MyTable from './MyTable'
import StructuredTable from './StructuredTable'
import './style.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const MangeMenu = () => {

  const [isMange, setIsMange] = useState(false)
  let  token =  JSON.parse(localStorage.getItem('authtoken')) || ''
  let venuePermission = JSON.parse(localStorage.getItem('moduleUserPermission')) || []
  let [selectedVenue, setSelectedVenue] = useState('')
  let [menuData, setMenuData] = useState([])
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState([])
  const [selectedMenuType, setSelectedMenuType] = useState([]);
  const [menuTypeFilter, setMenuTypeFilter] = useState([]);
  const [isFilter, setIsFilter] = useState(true)

  const [fullName, setFullName] = useState(
    JSON.parse(localStorage.getItem("catering_userName")) || ""
  );


  useEffect(() => {
    console.log(token)
    if (token?.token.length < 1) {
      localStorage.clear();
      return navigate("/")}
    if (venuePermission.length > 0) {
      console.log("venuePermission-------------", venuePermission[0]);
      if (venuePermission[0].VenueName != undefined) {
        fetchDetails(venuePermission[0].VenueName)
        setSelectedVenue(venuePermission[0].VenueName)
      }
      else {
        fetchDetails(venuePermission[0].HotelName);
        setSelectedVenue(venuePermission[0].HotelName);

      }
    }
  }, [])


  function handleChange(e) {
    setSelectedVenue(e.target.value)
    let name = e.target.value
    // console.log(`${BASE_URL}/moduleUser/getMenuForVenue/${name}`)
    fetchDetails(name)
  };
  function fetchDetails(name) {
    axios.get(`${BASE_URL}/moduleUser/getMenuForVenue/${encodeURIComponent(name)}`)
      .then(res => {
        console.log('-------response is -------', res, name)
        setMenuData(res.data)
        setFilterData(res.data)
        setSelectedMenuType(res.data)
      })
      .catch(err => console.log(err))
  };

  function updateFetchDetails() {
    fetchDetails(selectedVenue)
  }


  return (<>
    <div className="NavbarConatiner">
      <Navbar name={fullName} isShow={true} />
    </div>
    <div className='manageContainer'>
      <div className='topContainer'>
        <div>
          <button className='manageButton' onClick={() => setIsMange(val => !val)} > {isMange ? <>View</> : <> Manage</>} </button>
        </div>
        <div> <h1 className='manageMenu'>Manage Menu</h1> </div>
        <div className='FilterContainer'>
          <div>
            <Select
              className='mainSelectTag'
              name="venue"
              id="venue"
              onChange={handleChange}
              value={selectedVenue?.length > 0 ? selectedVenue : ''}
            >
              {venuePermission.map((item, index) => {
                console.log(item)
                return <MenuItem key={index} value={item.VenueName || item.HotelName}> {item.VenueName ? <>{item.VenueName}</> : <>{item.HotelName}</>} </MenuItem>
              })}

            </Select>
          </div>
          <div className='dateBox'>
            <input className='dateInput' type="date" onChange={(e) => {
              setFilterData(menuData.filter((item) => e.target.value == item.date))
              setIsFilter(true)
            }} />
            
          </div>

          <div>
            <select className='menuTypeFilter' value={selectedMenuType} onChange={(e) => {

              setMenuTypeFilter(filterData.filter((item) => e.target.value == item.menuType))
              setIsFilter(false)
              console.log(e.target.value)
            }}>
              <option value="">Select MenuType</option>
              <option value="Refreshments">Refreshments</option>
              <option value="Breakfast">Breakfast</option>
              <option value="Lunch">Lunch</option>
              <option value="Dinner">Dinner</option>
            </select>
          </div>
        </div>
      </div>





      {/* Menu data */}
      {isFilter ? (
        <div className='MenuData'>
          {isMange ? <MyTable data={filterData} updateFetchDetails={updateFetchDetails} /> : <StructuredTable data={filterData} />}
        </div>
      ) : (
        <div className='MenuData'>
          {isMange ? <MyTable data={menuTypeFilter} updateFetchDetails={updateFetchDetails} /> : <StructuredTable data={menuTypeFilter} />}
        </div>
      )}

    </div>


  </>
  )
}

export default MangeMenu