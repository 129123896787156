import logo from './logo.svg';
import './App.css';
import AllRoutes from './Pages/Allroutes/AllRoutes.jsx';
import axiosInstance from './services/axios.js';
import { BASE_URL } from './common/constant';
function App() {
  
  axiosInstance.defaults.baseURL = `${BASE_URL}`;


  return (
    <>
    
    <AllRoutes/>
    </>
  );
}

export default App;
