import React, { useEffect, useState } from "react";
import style from "./AddFopBasedVenues.module.css";
import { Link } from "react-router-dom";
import SubNavbar from "../SubNavbar/SubNavbar.jsx";
import { fetchVenu } from "../../../common/venues.js";
import { BASE_URL } from "../../../common/constant.js";
import areAllPropertiesFilled from "../../../common/areAllPropertiesFilled.js";
import axios from "axios";
import Swal from "sweetalert2";

function AddFopBasedVenues() {
  const [venues,setVenues]=useState([]);
  const [cities,setCities] =useState([]);
  const [data, setData] = useState({
    ClusterName: "Delhi",
    VenueName: "Indira Gandhi Stadium Complex, New Delhi",
    POCName: "",
    POCContactNumber: "",
    TotalAthletesCapacityCount: "",
    TotalSupportStaffsCount: "",
    TotalTechnicalOfficialsCount: "",
    TotalCDMsCount: "",
    venue_code:''
  });
  const [filteredVenue, setFilterdVenues] = useState([]);
 
useEffect(()=>{
  axios.get(`${BASE_URL}/get/allVenueSport`)
  .then(res=> {
   
    if(res?.data?.length >0 ){
      setVenues(res.data)
      filterUniqueCities(res.data)
     console.log(res.data)
    }
  })
},[])
function filterUniqueCities(data){
  // console.log(data)
  if(data?.length>0){
    const uniqueCityNames = [...new Set(data.map(item => item.city))];
    setCities(uniqueCityNames)
    console.log(cities)
  }

}

  useEffect(  () => {
    setFilterdVenues(venues.filter((item) => data.ClusterName == item.city));
    console.log(filteredVenue)
  }, [data.ClusterName]);

  

  function handleChange(event) {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(data,"datats")
  }

  function handleSubmit() {
    
    if(data.VenueName){
      data.venue_code=""
      venues.map(item=>{
        console.log(data.VenueName,item.venue)
        if(data.VenueName == item.venue ){
          data.venue_code= item.venue_code
        }
      })
    };
    console.log("Form data submitted:", data);

    if (!areAllPropertiesFilled(data))
        return Swal.fire('Please Enter all Details!')

    axios.post(`${BASE_URL}/addVenue/fopBasedVenu`, data)
    .then(res => {
        if (res.data == "already exist") {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.msg,
            })
        }
        Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: res.msg, 
        });
     
    })
    .catch(error => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
        })
    });
};

  return (
    <>
      <div className={style.mainContainer}>
        <div className={style.Nav}>
          <SubNavbar />
        </div>
        <div className={style.FOPform}>
          <h1 className={style.heading}>Add FOP Venue</h1>
          {/* Add FOP-based Venues */}
          <h2 className={style.subHeading} style={{ color: "#423861" }}>
            Add Venue
          </h2>
          {/* ------ClusterName Name------- */}
          <div className={style.formFieldsWrap}>
            <div className={style.fopVenueChilds}>
              <label htmlFor="ClusterName" className={style.label}>
                Enter Cluster Name
              </label>
              <select
                name="ClusterName"
                id="ClusterName"
                className={style.select}
                onChange={handleChange}
              >
                {cities.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* ----Venue Name------- */}
            <div className={style.fopVenueChilds}>
              <label htmlFor="ClusterName" className={style.label}>
                Select Venue Name
              </label>
              <select
                name="VenueName"
                id="VenueName"
                className={style.select}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                {filteredVenue.map((item, index) => {
                  return (
                    <option key={index} value={item.venue}>
                      {" "}
                      {item.venue}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* ----------Poc Name -----------*/}
            <div className={style.fopVenueChilds}>
              <label htmlFor="POCName" className={style.label}>
                POC Name
              </label>

              <input
                type="text"
                id="POCName"
                name="POCName"
                placeholder="Enter POC Name"
                onChange={handleChange}
                className={style.input}
              />
            </div>

            <div className={style.fopVenueChilds}>
              <label htmlFor="POCContactNumber" className={style.label}>
                POC Contact Number
              </label>

              <input
                type="number"
                id="POCContactNumber"
                name="POCContactNumber"
                placeholder="Enter POC Contact Number"
                pattern="[0-9]{10}"
                maxLength="10"
                onChange={handleChange}
                className={style.input}
              />
            </div>
          </div>

          <h2 className={style.subHeading}>Add Total Capacity</h2>

          <div className={style.formFieldsWrap}>
            <div className={style.fopVenueChilds}>
              <label
                htmlFor="TotalAthletesCapacityCount"
                className={style.label}
              >
                Enter Total Athletes Capacity Count
              </label>

              <input
                type="number"
                id="TotalAthletesCapacityCount"
                name="TotalAthletesCapacityCount"
                placeholder="Athletes Capacity"
                onChange={handleChange}
                className={style.input}
              />
            </div>

            <div className={style.fopVenueChilds}>
              <label htmlFor="TotalSupportStaffsCount" className={style.label}>
                Enter Total Support Staff’s Count
              </label>

              <input
                type="number"
                id="TotalSupportStaffsCount"
                name="TotalSupportStaffsCount"
                placeholder="Support Staff’s"
                pattern="[0-9]*"
                inputMode="numeric"
                onChange={handleChange}
                className={style.input}
              />
            </div>

            <div className={style.fopVenueChilds}>
              <label
                htmlFor="TotalTechnicalOfficialsCount"
                className={style.label}
              >
                Enter Total Technical Officials Count
              </label>

              <input
                type="number"
                id="TotalTechnicalOfficialsCount"
                name="TotalTechnicalOfficialsCount"
                placeholder="Technical Officials"
                onChange={handleChange}
                className={style.input}
              />
            </div>

            <div className={style.fopVenueChilds}>
              <label htmlFor="cdmsCount" className={style.label}>
                Enter Total CDM’s Count
              </label>

              <input
                type="number"
                id="TotalCDMsCount"
                name="TotalCDMsCount"
                placeholder="CDM’s Count"
                onChange={handleChange}
                className={style.input}
              />
            </div>
          </div>

          <button onClick={handleSubmit} className={style.button}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default AddFopBasedVenues;
