import axios from "axios";
import { BASE_URL } from "../../common/constant";
import './MyTable.css'

const MyTable = ({ data,updateFetchDetails }) => {
    console.log(data)
    data = data.sort(customSort)

    function handleDelete(id){
        console.log(id)
        axios.delete(`${BASE_URL}/moduleUser/deleteMenuItem/${id}`)
        .then(res=>{console.log(res)
            updateFetchDetails()
        })
        .catch(err=>console.log(err))
    }


    return (
        <div>
        
            <table className="my-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Player Type</th>
                        <th>Venue</th>
                        <th>Date</th>
                        <th>Menu Type</th>
                        <th>Menu Item</th>
                        <th> Delete </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.playerType}</td>
                            <td>{item.venue}</td>
                            <td>{item.date}</td>
                            <td>{item.menuType}</td>
                            <td>{item.menuItem}</td>
                            <td> <button className="DltBtn" onClick={() => handleDelete(item.id)}
                            >Delete</button> </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};



function customSort(a, b) {
    if (a.date !== b.date) {
        return a.date.localeCompare(b.date);
    }
    if (a.playerType !== b.playerType) {
        return a.playerType.localeCompare(b.playerType);
    }
    return a.menuType.localeCompare(b.menuType);
}
export default MyTable;