import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import "./ModuleAdminHomepage.css";
import imgLogo from "../../assets/National-games-white-logo.png";
import games_img from "../../assets/games_img.svg";

import manageAdminSvg from "../../assets/manage_module_admins_img.svg";
import ForecastManagement_img from "../../assets/forecast_logo_img.png";
const ModuleAdminHomepage = () => {
  //setauthtoken.token
  // const [token, setToken] = useState(
  //   JSON.parse(localStorage.getItem("authtoken.token")) || ""
  // );
  const [fullName, setFullName] = useState(
    JSON.parse(localStorage.getItem("catering_userName")) || ""
  );
  // const [user_level, setUserLevel] = useState(
  //   JSON.parse(localStorage.getItem("catering_user_level")) || ""
  // );
  const navigate = useNavigate();

  return (
    <div className="admin_main">
      <div className="admin_body">
        <div className="upperLogoandNavSection">
          <div className="imageLogoContainer">
            <img src={imgLogo} alt="" />
            <p>Catering Management Control Panel</p>
          </div>
          {/* <div className='textLogoContainer'>
            
          </div> */}
          <Navbar name={fullName} />
        </div>
        <div>
          {/* main container with 3 flex divs */}
          <div className="mainFlexBox">
            {/* first flex box */}
            <div className="MainlinkContainer">
              <div>
                {/* image will be here */}
                <div className="linkConImgHolder">
                  <img
                    className="logoHolder"
                    src={imgLogo}
                    alt="national sports Logo"
                  />

                  <div className="nameHolder">
                    <p className="adminPanelName">Ng Goa Admin Panel</p>
                  </div>
                </div>
                <div className="fullNameDiv"> {fullName} </div>
                <div className="linksHolder">
                  <Link className="linkTag" to="/admin/addfopBasedVenues">
                    Add FOP Based Venues
                  </Link>
                  <Link className="linkTag" to="/admin/addAccomodationVenue">
                    Add Accomodation Venue
                  </Link>
                  <Link className="linkTag" to="/admin/addSubadmin">
                    Create Admin User
                  </Link>
                  <Link
                    className="linkTag"
                    to="/admin/addCateringServiceProvider"
                  >
                    Add Catering Service Provider
                  </Link>
                  {/* <Link className={style.linkTag} to="/admin/forecastRequestManagement">ForeCast Request Management</Link> */}
                </div>
                <img className="games_img" src={games_img} alt="" />
              </div>
            </div>
            {/* second flex box */}
            <div className="mainCardsContianer">
              <div
                className="cardContainer cardMangeAdmin"
                onClick={() => navigate("/admin/manageSubAdmin")}
              >
                <img className="cardImg" src={manageAdminSvg} alt="" />

                <p>Manage Module User</p>
              </div>
              <div
                className="foreCastManggementCard cardContainer"
                onClick={() => navigate("/admin/forecastRequestManagement")}
              >
                <img className="cardImg" src={ForecastManagement_img} alt="" />
                ForeCast Request Management
              </div>

              <div
                style={{ backgroundColor: "teal" }}
                className="cardContainer foreCastManggementCard"
                onClick={() => navigate("/admin/manageAccomodationVenue")}
              >
                <img className="cardImg" src={ForecastManagement_img} alt="" />
                Manage Accomodation Venue
              </div>

              <div
                style={{ backgroundColor: "#ff9696" }}
                className="cardContainer foreCastManggementCard"
                onClick={() => navigate("/admin/mangageFopVenue")}
              >
                <img className="cardImg" src={ForecastManagement_img} alt="" />
                Manage FOP Based Venue
              </div>
            

           
            </div>
            {/* Third flex box */}
            <div className="updateBox"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleAdminHomepage;
