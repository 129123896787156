import React, { useState, useEffect } from "react";
import style from "../ManageSubAdmin/ManageSubAdmin.module.css";
import SubNavbar from "../SubNavbar/SubNavbar";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../common/constant";

const ManageAccomodationVenue = () => {
  const [data, setData] = useState([]);

  const [editedData, setEditedData] = useState({
    HotelName: "",
    HotelState: "",
    HotelCity: "",
    POCName: "",
    POCContactNumber: "",
    Pincode: "",
    GoogleMapLink: "",
    TotalAthletesCapacityCount: "",
    TotalSupportStaffsCount: "",
    TotalTechnicalOfficialsCount: "",
    TotalCDMsCount: "",
  });
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/get/accomodationBasedVenue`
      );
      const jsonData = await response.json();
      setData(jsonData);
      console.log(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (id) => {
    const selectedItem = data.find((item) => item.id === id);
    setEditedData(selectedItem);
    setIsEditModalVisible(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleInputChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      if (!id) {
        console.error("Invalid ID for deletion");
        return;
      }

      const response = await fetch(
        `${BASE_URL}/hotel/delete/accomodationBasedVenue/${id}`,
        {
          method: "DELETE",
        }
      );

      console.log("Response:", response);

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message);
      }
      Swal.fire("Deleted!", "Accomodation Deleted Successfully!", "success");
      console.log("Accommodation deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error deleting accommodation:", error.message);
      // Handle the error, show an error message, etc.
    }
  };

  const handleSave = async () => {
    console.log("------------", editedData.id);
    try {
      const response = await fetch(
        `${BASE_URL}/hotel/update/accommodationDetails/${editedData.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedData),
        }
      );

      if (response.ok) {
        Swal.fire("Updated!", "Accomodation Updated Successfully!", "success");
        console.log("Data updated successfully.");
        handleCloseEditModal();
        fetchData();
      } else {
        console.error("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={style.popUpEditSubAdmin}>
      <div  className={style.subAdmin}>
        <SubNavbar />
        <div className={style.subAdminContainer}>
          <h2>Manage Accomodation Venu's Catring</h2>
          <h3>Home - Accomodation</h3>
        </div>
        <div className={style.subAdminDetails}>
          <h2 style={{ textAlign: "center" }}>Manage Accomodation</h2>

          {/* /// Edit Modals */}
          {isEditModalVisible && (
            <div className={style.modal}>
              <div className={style.modalcontent}>
                <span className={style.close} onClick={handleCloseEditModal}>
                  &times;
                </span>
                <h2>Edit Details</h2>

                <div className={style.modalcontent}>
                  <label htmlFor="HotelName">Hotel Name:</label>
                  <h2>{editedData.HotelName}</h2>
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="HotelCity">Hotel City:</label>
                  <input
                    type="text"
                    id="HotelCity"
                    name="HotelCity"
                    value={editedData.HotelCity}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="pocName">POC Name:</label>
                  <input
                    type="text"
                    id="POCName"
                    name="POCName"
                    value={editedData.POCName}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="pocContactNumber">POC Contact Number:</label>
                  <input
                    type="text"
                    id="POCContactNumber"
                    name="POCContactNumber"
                    value={editedData.POCContactNumber}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="Pincode">Pincode:</label>
                  <input
                    type="number"
                    id="Pincode"
                    name="Pincode"
                    value={editedData.Pincode}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="GoogleMapLink">GoogleMapLink:</label>
                  <input
                    type="text"
                    id="GoogleMapLink"
                    name="GoogleMapLink"
                    value={editedData.GoogleMapLink}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="TotalSupportStaffsCount">
                    Total Support Staffs Count:
                  </label>
                  <input
                    type="number"
                    id="TotalSupportStaffsCount"
                    name="TotalSupportStaffsCount"
                    value={editedData.TotalSupportStaffsCount}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="TotalAthletesCapacityCount">
                    Total Athletes Capacity Count:
                  </label>
                  <input
                    type="number"
                    id="TotalAthletesCapacityCount"
                    name="TotalAthletesCapacityCount"
                    value={editedData.TotalAthletesCapacityCount}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="TotalTechnicalOfficialsCount">
                    Total Technical Officials Count:
                  </label>
                  <input
                    type="number"
                    id="TotalTechnicalOfficialsCount"
                    name="TotalTechnicalOfficialsCount"
                    value={editedData.TotalTechnicalOfficialsCount}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <div className={style.modalcontent}>
                  <label htmlFor="TotalCDMsCount">Total CDMs Count:</label>
                  <input
                    type="number"
                    id="TotalCDMsCount"
                    name="TotalCDMsCount"
                    value={editedData.TotalCDMsCount}
                    onChange={handleInputChangeEdit}
                  />
                </div>
                <button onClick={handleSave} className={style.updateButton}>
                  Update
                </button>
              </div>
            </div>
          )}

          {/* *************************************************** */}
          <div className={style.table2}>
          <div
              className={`${style.accomadationTableHeader} position-relative rounded-top-left-sm py-4 fw-bold body-text`}
              style={{
                borderTopLeftRadius: "1rem",
              }}
            >
                <div>id </div>
                <div>Hotel Name</div>
                <div>POC Name</div>
                <div>POC Contact Number</div>
                <div>Hotel State</div>
                <div>Hotel City</div>
                <div>Pincode</div>
                <div>GoogleMapLink </div>
                <div>Total Athletes Capacity Count</div>
                <div>Total Support Staffs Count</div>
                <div>Total Technical Officials Count</div>
                <div>Total CDMs Count</div>
                <div>Actions</div>
             </div>
             <div className={style.bodyContainer}>
              {data.map((item, index) => (
               <div
                    style={{
                      background: index % 2 === 0 ? "#D8C7F8" : "#EEE5FF",
                    }}
                    className={style.accomadationDivContainer}
                    key={index}
                  >
                  <div>{item.id}</div>
                  <div>{item.HotelName}</div>
                  <div>{item.POCName}</div>
                  <div>{item.POCContactNumber}</div>
                  <div>{item.HotelState}</div>
                  <div>{item.HotelCity}</div>
                  <div>{item.Pincode}</div>
                  <div>{item.GoogleMapLink}</div>
                  <div>{item.TotalAthletesCapacityCount}</div>
                  <div>{item.TotalSupportStaffsCount}</div>
                  <div>{item.TotalTechnicalOfficialsCount}</div>
                  <div>{item.TotalCDMsCount}</div>
                  <div>
                  <div className={style.subAdminActions}>
                    <button
                      className={style.EditsubAdmin}
                      onClick={() => handleEdit(item.id)}
                    >
                      Edit
                    </button>
                    <button
                     className={style.EditsubAdmin}
                      onClick={() => handleDelete(item.id)}
                    >
                      Delete
                    </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAccomodationVenue;
