import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../Login/Login'
import ModuleAdminHomepage from '../ModuleAdminHomepage/ModuleAdminHomepage'
import ModuleUserHomepage from '../ModuleUserHomepage/ModuleUserHomepage'
import AddFopBasedVenues from '../Subpages/AddFopBasedVenue/AddFopBasedVenues'
import AddAccomodationVenue from '../Subpages/AddAccommodationVenue/AddAccomodationVenue'
import AddSubAdmin from '../Subpages/AddSubAdmin/AddSubAdmin'
import AddCateringServiceProvider from '../Subpages/AddCateringServiceProvider/AddCateringServiceProvider'
import ForecastRequestManagement from '../Subpages/ForecastRequestManagement/ForecastRequestManagement'
import ManageSubAdmin from '../Subpages/ManageSubAdmin/ManageSubAdmin'
import ManageAccomodationVenue from '../Subpages/ManageAccommodationVenue/ManageAccomodationVenue'
import MangageFopVenue from '../Subpages/ManageFopVenue/MangageFopVenue'
import MangeMenu from '../MangeMenuFOrHotelsVenues'



const AllRoutes = () => {
  return (
    <Routes>
      {/* Common Login */}
      <Route path='/' element={<Login />} />
      {/* Main Admin Page */}
      <Route path='/admin' element={<ModuleAdminHomepage />} />
      {/* admin Subpages  */}
      <Route path='/admin/addfopBasedVenues' element={<AddFopBasedVenues />} />
      <Route path='/admin/addAccomodationVenue' element={<AddAccomodationVenue />} />
      <Route path='/admin/addSubadmin' element={<AddSubAdmin />} />
      <Route path='/admin/addCateringServiceProvider' element={<AddCateringServiceProvider />} />
      <Route path='/admin/forecastRequestManagement' element={<ForecastRequestManagement />} />

      {/*   Admin Manage Pages */}
      <Route path='/admin/manageSubAdmin' element={<ManageSubAdmin />} />
      <Route path='/admin/manageAccomodationVenue' element={<ManageAccomodationVenue />} />
      <Route path='/admin/mangageFopVenue' element={<MangageFopVenue />} />
 
      <Route path='/module_user' element={<ModuleUserHomepage />} />
        <Route path='/module_user/manageMenu' element={<MangeMenu/>} />
     
   
        
{/* other modules */}
    
    </Routes>
  )
}

export default AllRoutes