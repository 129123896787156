import React, { useState, useEffect } from "react";
import style from "../ManageSubAdmin/ManageSubAdmin.module.css";
import SubNavbar from "../SubNavbar/SubNavbar";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../common/constant";

const MangageFopVenue = () => {
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState({
    POCName: "",
    POCContactNumber: "",
    TotalCDMsCount: "",
    TotalAthletesCapacityCount: "",
    TotalSupportStaffsCount: "",
    TotalTechnicalOfficialsCount: "",
  });
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get/fopBasedVenue`);
      const jsonData = await response.json();
      setData(jsonData);
      console.log(jsonData, "ALL FOP VENUES")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (id, index) => {
    console.log("Clicked");
    const selectedItem = data[index];
    setEditedData({
      ...selectedItem,
    });
    setIsEditModalVisible(true);
    console.log(isEditModalVisible, selectedItem);
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleInputChangeEdit = (e) => {
    const { name, value } = e.target;
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    console.log(editedData);
    try {
      const response = await fetch(
        `${BASE_URL}/addVenue/update/fopBasedVenue/${editedData.EventVenueID}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            POCName: editedData.POCName,
            POCContactNumber: editedData.POCContactNumber,
            TotalCDMsCount: editedData.TotalCDMsCount,
            TotalAthletesCapacityCount: editedData.TotalAthletesCapacityCount,
            TotalSupportStaffsCount: editedData.TotalSupportStaffsCount,
            TotalTechnicalOfficialsCount:
              editedData.TotalTechnicalOfficialsCount,
          }),
        }
      );

      if (response.ok) {
        Swal.fire("Updated!", "FOP Venue Updated Successfully!", "success");
        console.log("Data updated successfully.");
        // Refresh data after successful update
        handleCloseEditModal();
        fetchData();
      } else {
        console.error("Failed to update data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/addVenue/delete/fopBasedVenue/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message);
      }
      Swal.fire("Deleted!", "FOP Venue Deleted Successfully!", "success");
      // Accommodation deleted successfully, update UI or show a success message
      console.log("FOP Venue deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error deleting accommodation:", error.message);
      // Handle the error, show an error message, etc.
    }
  };

  return (
    <>
      <div className={style.subAdmin}>
        <SubNavbar />
        <div className={style.subAdminContainer}>
          <h2>Manage FOP Venue</h2>
          <h3>Home - Manage FOP Venue</h3>
        </div>
        <div className={style.subAdminDetails}>
          <h2 style={{ textAlign: "center" }}>Manage FOP Venue</h2>

          {/* /// Edit Modals */}
          {isEditModalVisible && (
            <>
              <div className={style.modal}>
                fa
                <div className={style.modalcontent}>
                  <span className={style.close} onClick={handleCloseEditModal}>
                    &times;
                  </span>
                  <h2>Edit Details</h2>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="ClusterName">
                      {" "}
                      <h3>Cluster Name:{editedData.ClusterName}</h3>{" "}
                    </label>
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="VenueName">
                      <h3> Venue Name: {editedData.VenueName}</h3>{" "}
                    </label>
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="pocName">POC Name:</label>
                    <input
                      type="text"
                      id="POCName"
                      name="POCName"
                      value={editedData.POCName}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="pocContactNumber">
                      POC Contact Number:
                    </label>
                    <input
                      type="text"
                      id="POCContactNumber"
                      name="POCContactNumber"
                      value={editedData.POCContactNumber}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="TotalCDMsCount">Total CDMs Count:</label>
                    <input
                      type="number"
                      id="TotalCDMsCount"
                      name="TotalCDMsCount"
                      value={editedData.TotalCDMsCount}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="TotalSupportStaffsCount">
                      Total Support Staffs Count:
                    </label>
                    <input
                      type="number"
                      id="TotalSupportStaffsCount"
                      name="TotalSupportStaffsCount"
                      value={editedData.TotalSupportStaffsCount}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="TotalAthletesCapacityCount">
                      Total Athletes Capacity Count:
                    </label>
                    <input
                      type="number"
                      id="TotalAthletesCapacityCount"
                      name="TotalAthletesCapacityCount"
                      value={editedData.TotalAthletesCapacityCount}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <div className={style.updateFOPdivs}>
                    <label htmlFor="TotalTechnicalOfficialsCount">
                      Total Technical Officials Count:
                    </label>
                    <input
                      type="number"
                      id="TotalTechnicalOfficialsCount"
                      name="TotalTechnicalOfficialsCount"
                      value={editedData.TotalTechnicalOfficialsCount}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                  <button onClick={handleSave} className={style.updateButton}>
                    Update
                  </button>
                </div>
              </div>
            </>
          )}

          {/* *************************************************** */}
          <div className={style.table2}>
            <div
              className={`${style.fopTableHeader} position-relative rounded-top-left-sm py-4 fw-bold body-text`}
              style={{
                borderTopLeftRadius: "1rem",
              }}
            >
              <div>Event Venue ID</div>
              <div>Cluster Name</div>
              <div>Venue Name</div>
              <div>POC Name</div>
              <div>POC Contact Number</div>
              <div>Total CDMs Count</div>
              <div>Total Athletes Capacity Count</div>
              <div>Total Support Staffs Count</div>
              <div>Total Technical Officials Count</div>
              <div>Actions</div>
            </div>
            <div className={style.bodyContainer}>
              {data.map((item, index) => (
                <div
                style={{
                  background: index % 2 === 0 ? "#D8C7F8" : "#EEE5FF",
                }}
                className={style.fopDivContainer}
                key={index}
              >
                  <div>{item.EventVenueID}</div>
                  <div>{item.ClusterName}</div>
                  <div>{item.VenueName}</div>
                  <div>{item.POCName}</div>
                  <div>{item.POCContactNumber}</div>
                  <div>{item.TotalCDMsCount}</div>
                  <div>{item.TotalAthletesCapacityCount}</div>
                  <div>{item.TotalSupportStaffsCount}</div>
                  <div>{item.TotalTechnicalOfficialsCount}</div>
                  <div>
                    <div className={style.subAdminActions}>
                      <button
                        className={style.EditsubAdmin}
                        onClick={() => handleEdit(item.EventVenueID, index)}
                      >
                        Edit
                      </button>
                      <button
                        className={style.EditsubAdmin}
                        onClick={() => handleDelete(item.EventVenueID)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MangageFopVenue;
