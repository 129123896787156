import React, { useEffect, useState } from "react";
import style from "../AddFopBasedVenue/AddFopBasedVenues.module.css";
import SubNavbar from "../SubNavbar/SubNavbar";
import { BASE_URL } from "../../../common/constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function AddCateringServiceProvider() {
  let LunchTypes = [
    { name: " Breakfast" },
    { name: "Lunch " },
    { name: " Dinner" },
    { name: " Refreshments " },
  ];
  const [addMenu, setAddMenu] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
    location: "",
    capacity: "",
    otherDetails: "",
    menu: {},
  });
  const [selectedMenu, setSelectedMenu] = useState({ "Lunch ": [] });
  const [selectedLunchType, setSelectedLunchType] = useState("Lunch ");

  let navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLunchType = (type) => {
    setSelectedLunchType(type);
    if (!selectedMenu[type]) {
      setSelectedMenu({ ...selectedMenu, [type]: [] });
    }
  };

  function addMenuToList(item) {
    if(item.length==0) {
      return (Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Cannot Submit Empty Fields!',
      }))
    }
    if (addMenu?.length > 0) {
      if (selectedMenu[selectedLunchType]?.includes(item)) {
        return alert ("Food already in the list")

        const filterItems = selectedMenu[selectedLunchType].filter(
          (food) => food !== item
        );
        setSelectedMenu({
          ...selectedMenu,
          [selectedLunchType]: [...filterItems],
        });
      } else {
        setSelectedMenu({
          ...selectedMenu,
          [selectedLunchType]: [...selectedMenu[selectedLunchType], item],
        });
      }
    } else {
      return alert("add item");
    }
    setAddMenu("");
  }

  function removeItemFromMenu(item,key){
    console.log(item,key)
  //  data.lunch = data.lunch.filter(item => item !== 'a');
    let newdata =  selectedMenu[key].filter(val => val !== item);
    setSelectedMenu({...selectedMenu,[key]:[...newdata] })
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle form submission here, e.g., send data to a server or perform other actions
    console.log("Form submitted:", formData);
    axios
      .post(`${BASE_URL}/catering/addCatringService`, formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.error,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      menu: { ...selectedMenu },
    });
  }, [selectedMenu]);

  return (
    <div className={style.mainContainer}>
      <div className={style.Nav}>
        <SubNavbar />
      </div>

      <div className={style.FOPform}>
        <h1 className={style.heading}>Add Catering Service Provider Details</h1>
        <div className={style.formFieldsWrap}>
          <div className={style.fopVenueChilds}>
            <label className={style.label} htmlFor="name">
              Enter the Name of the Catering Service Provider:
            </label>
            <input
              className={style.input}
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label} htmlFor="contactNumber">
              Enter Contact Number:
            </label>
            <input
              className={style.input}
              type="number"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
            />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label} htmlFor="email">
              Enter Email Id:
            </label>
            <input
              className={style.input}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label} htmlFor="email">
              Enter Location :
            </label>
            <input
              className={style.input}
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label} htmlFor="capacity">
              Enter Capacity :{" "}
            </label>
            <input
              className={style.input}
              type="number"
              id="capacity"
              name="capacity"
              value={formData.capacity}
              onChange={handleChange}
            />
          </div>

          <div className={style.fopVenueChilds}>
            <label className={style.label} htmlFor="otherDetails">
              Enter other details:
            </label>
            <textarea
              className={style.textarea}
              id="otherDetails"
              name="otherDetails"
              value={formData.otherDetails}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* ------------------display box ------------*/}

        <div className={StyleSheet.selectedMenu}>
          <h2>Selected menu</h2>
          {Object.keys(selectedMenu).map((key) => {
            return (
              <div className={style.selectedmenulist}>
                {selectedMenu[key]?.length !== 0 && (
                  <div key={key} className={style.selectedmenu}>
                    <p className={style.selectedKeys}>{key}:</p>
                    {selectedMenu[key].map((item, ind) => (
                      <p className={style.listitem}> {item}  <button onClick={()=>removeItemFromMenu(item,key)} className={style.menuCrossBtn} > X</button>  </p>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {/* -----------buttons--------------------------- */}
        <div className={style.addmenu}>
          <div className={style.menuButtons}>
            {LunchTypes.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    handleLunchType(item.name);
                  }}
                >
                  {item.name}
                </button>
              );
            })}
          </div>

          {/* -------------------- */}
          <>
            {LunchTypes.length > 0 &&
              LunchTypes.map((item, index) => {
                let val = "";
                return (
                  <>
                    {" "}
                    {selectedLunchType == item.name && (
                      <div key={index} className={style.fopVenueChilds}>
                        {" "}
                        <p>Add {item.name}</p>
                        <div className={style.btnaddmenu}>
                          <input
                            className={style.input}
                            type="text"
                            onChange={(e) => setAddMenu(e.target.value)}
                            defaultValue={addMenu}
                          />
                          <button
                            onClick={() => {
                              addMenuToList(addMenu);

                              // try {
                              //   formData.menu[item.name].push(val);
                              //   setFormData({
                              //     ...formData,
                              //     menu: { ...formData.menu },
                              //   });
                              // } catch {
                              //   setFormData({
                              //     ...formData,
                              //     menu: {
                              //       ...formData.menu,
                              //       [item.name]: [val],
                              //     },
                              //   });
                              // }

                              console.log(formData.menu);
                            }}
                          >
                            {" "}
                            Add Menu{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}{" "}
          </>
        </div>
        <button onClick={handleSubmit} className={style.button}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddCateringServiceProvider;
