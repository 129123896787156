import React, { useState } from 'react';
import axios from "axios";
import { BASE_URL } from "../../common/constant";

// const StructuredTable = ({data}) => {
export default function StructuredTable({ data }) {

  const groupedData = groupDataTogether(data)
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Venue</th>
            <th>Menu Type</th>
            <th>Menu</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(groupedData).map((group, index) => (
            <tr key={index}>
              <td>{group.playerType}</td>
              <td>{group.date}</td>
              <td>{group.venue}</td>
              <td>{group.menuType}</td>
              <td>{group.menuItem.join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


function groupDataTogether(data) {
  const groupedData = data.reduce((groups, item) => {
    const key = `${item.playerType}-${item.venue}-${item.date}-${item.menuType}`;
    if (!groups[key]) {
      groups[key] = {
        playerType: item.playerType,
        venue: item.venue,
        date: item.date,
        menuType: item.menuType,
        menuItem: [],
      };
    }
    groups[key].menuItem.push(item.menuItem);
    return groups;
  }, {});
  console.log(groupedData)
  return groupedData
}